@import "../variables.scss";

@mixin tooltip-triangle {
  content: "";
  position: absolute;
  border-color: transparent $text-color transparent transparent;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip {
  position: absolute;
  z-index: 9999;
  background: $text-color;
  color: $main-bg;
  padding: 0.625vw 2.344vw;
  border-radius: 0.391vw;
  font-size: .9em;
  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &_right .tooltip__triangle {
    border-width: 0.586vw 0.781vw 0.586vw 0;
    border-color: transparent $text-color transparent transparent;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }
  &_left .tooltip__triangle {
    border-width: 0.586vw 0 0.586vw 0.781vw;
    border-color: transparent transparent transparent $text-color;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
  &_bottom .tooltip__triangle {
    border-width: 0 0.586vw 0.781vw 0.586vw;
    border-color: transparent transparent $text-color transparent;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
  }
  &_top .tooltip__triangle {
    border-width: 0.781vw 0.586vw 0 0.586vw;
    border-color: $text-color transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &__container {
    position: relative;
  }
}