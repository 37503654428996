.canvas {
  grid-row-start: 2;
  grid-column-start: 7;
  grid-column-end: 15;
  text-align: center;
  display: grid;
  overflow: auto;
  transition: all 600ms ease-in-out;
  transform: translateX(0);
  width: 100%;
  height: 100%;

  &-toolbar-wider {
    grid-column-end: 13;
  }

  &-container {
    align-self: center;
    justify-self: center;
  }
}
