@import "../variables.scss";

$token-color: #666;
$option-color: #999;
$token-background: #9be5cf;

.prompt-token {
  &__wrapper {
    font-size: 90%;
    line-height: 1;
    position: relative;
  }

  &__input-wrapper {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 1px 4px;
    display: flex;
    font-weight: 400;
    overflow: hidden;
    flex-wrap: wrap;
    background-color: #fff;
  }

  &__input {
    color: $token-color;
    vertical-align: middle;
    border: none;
    flex-grow: 1;
    display: inline-block;
    &:focus {
      outline: none;
    }
  }

  &__option-list {
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    margin-top: 2px;
    max-height: 20vw;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    z-index: 1;
  }

  &__option {
    color: $option-color;
    padding: 5px;
    cursor: default;

    &__selected {
      background: #d4d4d4;
    }
  }

  &__token-wrapper {
    border-radius: 2px;
    color: $token-color;
    background: $token-background;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    overflow: hidden;
    border: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: space-between; /* ボタンを右端に寄せる */
    padding: 0 2px;

    &__disabled {
      background: #aaa;
    }

    @for $i from 1 through 20 {
      $percentage: $i * 10;
      &__prompt-weighted#{$percentage} {
        background-color: darken($token-background, $percentage/3);
      }
    }
    @for $i from 1 through 20 {
      $percentage: $i * 10;
      &__prompt-weighted#{-$percentage} {
        background-color: lighten($token-background, $percentage/3);
      }
    }
  }

  &__control-buttons {
    display: flex;
    gap: 0.1vw;
  }

  &__token-remove-button {
    cursor: pointer;

    svg {
      fill: $token-color;
      vertical-align: middle;
    }
  }

  &__token-disable-button {
    cursor: pointer;

    svg {
      fill: $token-color;
      vertical-align: middle;
    }
  }

  &__token-down-button {
    cursor: pointer;

    svg {
      fill: $token-color;
      vertical-align: middle;
    }
  }
  &__token-up-button {
    cursor: pointer;

    svg {
      fill: $token-color;
      vertical-align: middle;
    }
  }

  &__token-value {
    user-select: none;
    padding: 2px 5px;
    vertical-align: middle;
  }
}
