.toolbar {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 15;
  grid-column-end: 19;
  background: $toolbar-bg;
  overflow-y: auto;
  height: 100vh;
  transition: all 0.3s;
  color: $text-color;

  &-toolbar-wider {
    grid-column-start: 13;
  }

  &-enter {
    transform: translateX(200%);
  }

  &-enter-active {
    transition: all 600ms ease-in-out;
    transform: translateX(0);
  }

  &-exit {
    transform: translateX(0);
  }

  &-exit-active {
    transform: translateX(200%);
    transition: all 600ms ease-in-out;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.171875vw 1.5625vw;
    background: $border-color;

    svg {
      width: 0.9375vw;
      height: 0.9375vw;
      cursor: pointer;
    }
  }

  &__drawing,
  &__content {
    // padding: 1vw 1vw;
    // padding: 0.75vw;
    padding: 0.6vw;
    display: grid;
    // grid-row-gap: 1.5vw;
    grid-row-gap: 0.5vw;
    font-size: 0.8vw;
    flex: 1;
  }

  &__drawing {
    background: lighten($toolbar-bg, 10%);
    // padding: 0.75vw 1vw;
  }

  &_search {
    grid-column-end: 16;
    z-index: 999;
    padding: 3.75vw 19.6875vw;
  }

  &__labeled_text_content {
    display: block;

    &__long {
      font-size: 65%;
      height: 10vw;
      width: 100%;
    }
    &__short {
      font-size: 75%;
      resize: None;
      width: 50%;
    }
  }

  &__composite_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // align-items: top;

    > div {
      flex: 1;
      margin: 0 10px;
    }
  }

  &__block {
    &-title {
      margin-bottom: 1.5625vw;
      font-weight: 600;
      font-size: 0.95em;
    }
  }

  &__divider {
    width: 100%;
    height: 0.156vw;
    background: $border-color;
    margin-bottom: 1.5625vw;
  }

  &__form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0.78125vw;

    &-label {
      margin: 0;
      align-self: center;
      font-weight: 600;
      font-size: 0.95em;
    }

    &-input {
      width: 100%;
      background: transparent;
      float: right;
      text-align: right;
      border: 0.156vw solid $border-color;
      font-size: 0.95em;
      padding: 0.234375vw 0.46875vw;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.78125vw;
    row-gap: 0.78125vw;

    &_one-col {
      grid-template-columns: 1fr;
    }

    &_three-col {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__option {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 2.25vw;
    background: $border-color;
    font-weight: 400;
    font-size: 0.95em;
    user-select: none;

    &_active {
      border: 0.3125vw solid $text-color;
    }

    &:hover {
      opacity: 0.8;
    }

    p {
      margin-left: 0.78125vw;
    }

    svg {
      width: 1.5625vw;
      height: 1.5625vw;
    }
  }

  &__action-btn {
    background: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 3.906vw;
    font-size: 0.95em;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}
