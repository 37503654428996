@import "../variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-column-start: 7;
  grid-column-end: 15;
  grid-row-start: 1;
  padding: 0 2.5%;
  transition: all 600ms ease-in-out;
  transform: translateX(0);

  &-toolbar-wider {
    grid-column-end: 13;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // width: 100%;
  }

  &__items-group {
    display: flex;

    &:nth-child(1) {
      .header__item {
        margin-right: 7.97%;
        user-select: none;
        justify-content: center;
      }
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      // justify-content: flex-end;
      justify-content: center;

      .header__item {
        margin-left: 7.97%;
      }
    }
  }

  &__item {
    cursor: pointer;

    svg {
      width: 1.6vw;
      height: 1.6vw;
      stroke: $text-color;
    }
  }

  &__upload-image-input {
    display: none;
  }

  .zoom-control {
    display: flex;
    align-items: center;
    color: $text-color;

    &__value {
      padding: 0 1.015625vw;
      cursor: default;
    }
  }

  .layer-control {
    display: flex;
    align-items: center;
    color: $text-color;

    &__value {
      padding: 0 1.015625vw;
      cursor: default;
    }

    // want to add a strike-out line if a layer is invisible
    &__invisible {
      svg {
        fill: $disabled-color;
      }
    }
  }
}
