@import "../variables.scss";

.preview {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 7;
  background-color: $menu-bg;
  z-index: 2;
  // width: 30vw;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    padding: 1.171875vw 1.5625vw;
    background: $border-color;

    svg {
      width: 0.9375vw;
      height: 0.9375vw;
      cursor: pointer;
    }
  }

  &__item {
    cursor: pointer;
    padding: 0 2.5%;

    svg {
      width: 1.4vw;
      height: 1.4vw;
      stroke: $text-color;
    }
  }

  &__push {
    margin-left: auto;
  }

  &__option_wrapper {
    display: flex;
  }

  &__option {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 4.6vw;
    background: $border-color;
    padding: 1.5625vw 1.172vw;
    font-weight: 600;
    // font-size: 0.95em;
    user-select: none;
    margin: 0vw 0.5vw 1vw 0.5vw;

    &_active {
      border: 1px solid $text-color;
      color: $text-color;
    }

    &:hover {
      opacity: 0.8;
    }

    &__option1 {
      width: 40%;
    }
    &__option2 {
      width: 20%;
    }

    &__stacked {
      flex: 1;
      padding: 0;
      width: 100%;
    }
  }

  &__option_stack_wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }

  &__mini {
    width: 50%;
    height: 100%;
  }
}
